<template>
  <router-view/>
</template>

<script setup lang="ts">
import { usePwaInstallEventStore } from 'stores/pwa-install-event-store';
import { onMounted } from 'vue';

const pwaInstallEventStore = usePwaInstallEventStore();

onMounted(() => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    pwaInstallEventStore.$patch({ event: e, isInstallable: true });
  });
});

if (window.matchMedia('(display-mode: standalone)').matches) {
  pwaInstallEventStore.$patch({ isInstallable: false });
}
</script>